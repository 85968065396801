<template>
  <v-container>
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        sm="4"
        md="4"
      >
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <router-link :to="{ name: 'Admin' }">
          <v-btn
            class=" ma-2 mt-6"
            color="success"
          >
            <v-icon
              left
            >
              mdi-home-outline
            </v-icon>Admin Home
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-card class="ml-2 mt-2">
      <v-card-title>
        {{ selectedFileType === '' ? '' : selectedFileType === 'AMA' ? 'AMA' : 'AAPA' }} Customer Omit Codes
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="5"
            sm="5"
          >
            <v-select
              v-model="selectedFileType"
              :items="fileTypes"
              outlined
              dense
              label="File Type"
              color="primary"
              item-value="fileType"
              item-text="fileType"
              @change="getOmitCodesStart"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
              v-model="currentId"
              label="Current ID"
              :readonly="true"
            />
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
              v-model="newId"
              label="New ID"
            />
          </v-col>
          <v-col
            cols="2"
            sm="2"
          >
            <v-btn
              class="mt-4"
              color="primary"
              small
              @change="addOmitID"
            >
              Add New ID
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
              v-model="currentZip"
              label="Current ZIP"
              :readonly="true"
            />
          </v-col>
          <v-col
            cols="4"
            sm="4"
          >
            <v-text-field
              v-model="newZip"
              label="New ZIP"
            />
          </v-col>
          <v-col
            cols="2"
            sm="2"
          >
            <v-btn
              class="mt-4"
              color="primary"
              small
            >
              Add New ZIP
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { nowwService } from '@/shared/services'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'OmitCodes',
  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      selectedFileType: '',
      currentId: '',
      newId: '',
      currentZip: '',
      newZip: '',
      fileTypes: [],
      omitCodes: [],
      resultsLoading: false
    }
  },

  created () {
    this.getFileTypes()
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      // if (listeners.includes(mutation.type)) {
      //   this.getOmitCodesStart()
      // }
    })
  },

  methods: {
    async getFileTypes () {
      this.resultsLoading = true
      await nowwService.getOmitCodeFileTypes()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.fileTypes = resp
            this.resultsLoading = false
          }
        })
    },
    async getOmitCodesStart () {
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      this.resultsLoading = true
      await nowwService.getOmitCodesStart({
        customerId: customerNowwId,
        fileType: this.selectedFileType
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.omitCodes = resp
            var omitCodeId = this.omitCodes.find(item => item.omitType === 2)
            if (omitCodeId) {
              this.currentId = omitCodeId.omitCode
            }
            var omitCodeZip = this.omitCodes.find(item => item.omitType === 1)
            if (omitCodeZip) {
              this.currentZip = omitCodeZip.omitCode
            }
            this.resultsLoading = false
          }
        })
    },
    async addOmitID () {
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      this.resultsLoading = true
      await nowwService.addOmitID({
        customerId: customerNowwId,
        newID: this.newId,
        fileType: this.selectedFileType
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.resultsLoading = false
          }
        })
    }
  }
}
</script>
