var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "space-between" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "4", md: "4" } },
            [
              _vm.showViewAsCustomer
                ? _c("ViewAsCustomer", { staticClass: "mt-5 ml-2" })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Admin" } } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "ma-2 mt-6", attrs: { color: "success" } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("\n            mdi-home-outline\n          ")
                      ]),
                      _vm._v("Admin Home\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ml-2 mt-2" },
        [
          _c("v-card-title", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.selectedFileType === ""
                    ? ""
                    : _vm.selectedFileType === "AMA"
                    ? "AMA"
                    : "AAPA"
                ) +
                " Customer Omit Codes\n    "
            )
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5", sm: "5" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.fileTypes,
                          outlined: "",
                          dense: "",
                          label: "File Type",
                          color: "primary",
                          "item-value": "fileType",
                          "item-text": "fileType"
                        },
                        on: { change: _vm.getOmitCodesStart },
                        model: {
                          value: _vm.selectedFileType,
                          callback: function($$v) {
                            _vm.selectedFileType = $$v
                          },
                          expression: "selectedFileType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4", sm: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Current ID", readonly: true },
                        model: {
                          value: _vm.currentId,
                          callback: function($$v) {
                            _vm.currentId = $$v
                          },
                          expression: "currentId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4", sm: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "New ID" },
                        model: {
                          value: _vm.newId,
                          callback: function($$v) {
                            _vm.newId = $$v
                          },
                          expression: "newId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2", sm: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4",
                          attrs: { color: "primary", small: "" },
                          on: { change: _vm.addOmitID }
                        },
                        [_vm._v("\n            Add New ID\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4", sm: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Current ZIP", readonly: true },
                        model: {
                          value: _vm.currentZip,
                          callback: function($$v) {
                            _vm.currentZip = $$v
                          },
                          expression: "currentZip"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4", sm: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "New ZIP" },
                        model: {
                          value: _vm.newZip,
                          callback: function($$v) {
                            _vm.newZip = $$v
                          },
                          expression: "newZip"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2", sm: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4",
                          attrs: { color: "primary", small: "" }
                        },
                        [_vm._v("\n            Add New ZIP\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }